@import url("https://fonts.googleapis.com/css2?family=Cormorant:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap");
*,
*::before,
*::after {
  box-sizing: border-box;
}

* {
  margin: 0;
}

html,
body {
  height: 100%;
}

body {
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
}

input,
button,
textarea,
select {
  font: inherit;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  overflow-wrap: break-word;
}

#root,
#__next {
  isolation: isolate;
}

body {
  font-family: "Helvetica Neue", Arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", Meiryo, sans-serif;
}

a {
  text-decoration: none;
  color: #000;
}

.c-header {
  padding: 1rem 0;
  display: flex;
  align-items: center;
  font-family: "Cormorant", serif;
}
@media screen and (max-width: 768px), print {
  .c-header {
    justify-content: space-between;
    padding: 1rem 0.6rem;
  }
}
.c-header__logo {
  width: 150px;
}
.c-header__logo > a > img {
  width: 100%;
  height: auto;
}
@media screen and (max-width: 768px), print {
  .c-header__logo {
    width: 100px;
  }
}
.c-header__nav {
  list-style: none;
  display: flex;
  padding-left: 2rem;
}
@media screen and (max-width: 768px), print {
  .c-header__nav {
    padding-left: 0rem;
    padding-top: 1rem;
    display: none;
    position: fixed;
    align-items: center;
    top: 0;
    left: 0;
    right: 0;
    background-color: #fff;
    z-index: 999;
  }
}
.c-header__nav > li {
  font-size: 18px;
}
@media screen and (max-width: 768px), print {
  .c-header__nav > li {
    text-align: center;
  }
}
.c-header__nav > li:not(:first-child) {
  margin-left: 3rem;
}
@media screen and (max-width: 768px), print {
  .c-header__nav > li:not(:first-child) {
    margin-left: 0;
    margin-top: 0.6rem;
  }
}
.c-header__hamberger {
  display: none;
}
@media screen and (max-width: 768px), print {
  .c-header__hamberger {
    display: block;
    margin-right: 0.6rem;
  }
}
.c-header__close {
  display: none;
}
@media screen and (max-width: 768px), print {
  .c-header__close {
    display: block;
  }
}

.c-main {
  width: 100%;
  text-align: center;
}

.c-section {
  padding: 4rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: wrap;
}
.c-section__heading {
  width: 100%;
  height: 80px;
  font-family: "Cormorant", serif;
  font-size: 50px;
  position: relative;
}
@media screen and (max-width: 768px), print {
  .c-section__heading {
    font-size: 20px;
    height: 30px;
  }
}
.c-section__heading > span {
  padding: 0 1rem;
  background-color: #fff;
  z-index: 2;
  position: relative;
}
.c-section__heading:after {
  content: "";
  display: block;
  width: 100%;
  height: 1px;
  background-color: #000;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
}
.c-section__body {
  font-size: 26px;
  font-weight: bold;
  margin-bottom: 2rem;
}
@media screen and (max-width: 768px), print {
  .c-section__body {
    font-size: 14px;
  }
}
.c-section__message, .c-section__characters, .c-section__date, .c-section__people {
  margin-bottom: 4.8rem;
}
.c-section__message {
  width: 185px;
  height: auto;
  margin-left: auto;
  margin-right: auto;
}
@media screen and (max-width: 768px), print {
  .c-section__message {
    width: 180px;
  }
}
.c-section__characters {
  width: 860px;
  height: auto;
}
@media screen and (max-width: 768px), print {
  .c-section__characters {
    width: 96%;
  }
}
.c-section__date {
  width: 270px;
  height: auto;
}
@media screen and (max-width: 768px), print {
  .c-section__date {
    width: 160px;
  }
}
.c-section__people {
  width: 1080px;
  height: auto;
  margin-bottom: 0;
}
@media screen and (max-width: 768px), print {
  .c-section__people {
    width: 96%;
  }
}

.c-intro__message {
  width: 680px;
  margin: 4rem 0;
}
@media screen and (max-width: 768px), print {
  .c-intro__message {
    margin: 3rem 0;
    width: 300px;
    height: auto;
  }
}
.c-intro-content {
  width: 100%;
  background-image: url(../../rpp/images/introduction/intro-bg.png);
  background-position: center;
  background-repeat: no-repeat;
  background-color: rgba(255, 255, 255, 0.85);
  background-blend-mode: lighten;
}
@media screen and (max-width: 768px), print {
  .c-intro-content {
    width: 96%;
    background-size: contain;
  }
}

.c-footer {
  width: 100%;
  height: 320px;
  background-image: url(../../rpp/images/footer-bg.svg);
  background-position: bottom;
  background-repeat: repeat-x;
  padding-top: 6em;
}
@media screen and (max-width: 768px), print {
  .c-footer {
    height: 196px;
    padding-top: 2em;
    background-image: url(../../rpp/images/footer-bg--sp.svg);
  }
}
.c-footer__logo {
  width: 250px;
  margin: 0 auto 1rem;
}
.c-footer__logo img {
  width: 100%;
  height: auto;
}
@media screen and (max-width: 768px), print {
  .c-footer__logo {
    width: 110px;
    margin: 0 auto;
  }
}
.c-footer-content {
  display: flex;
  justify-content: space-between;
}
@media screen and (max-width: 768px), print {
  .c-footer-content {
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
  }
}
.c-footer-content__copyright {
  font-family: "Cormorant", serif;
  color: #fff;
}
@media screen and (max-width: 768px), print {
  .c-footer-content__copyright {
    padding: 0 1rem;
    font-size: 12px;
    text-align: center;
  }
}
@media screen and (max-width: 768px), print {
  .c-footer-content__sns {
    margin-top: 0.6rem;
    margin-bottom: 0.6rem;
  }
}
.c-footer-content__sns > a > img {
  width: 44px;
  height: auto;
}
@media screen and (max-width: 768px), print {
  .c-footer-content__sns > a > img {
    width: 25px;
  }
}
.c-footer-content__sns > a:not(:first-child) {
  margin-left: 1rem;
}

.container, .c-header, .c-footer-content {
  width: 1100px;
  margin: 0 auto;
}
@media screen and (max-width: 768px), print {
  .container, .c-header, .c-footer-content {
    width: 100%;
  }
}