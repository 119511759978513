// base
@import "variables";
@import "normalize";
@import "mixins";
@import url('https://fonts.googleapis.com/css2?family=Cormorant:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap');

body {
  font-family: "Helvetica Neue",
    Arial,
    "Hiragino Kaku Gothic ProN",
    "Hiragino Sans",
    Meiryo,
    sans-serif;
}

a {
  text-decoration: none;
  color: #000;
}

.c-header {
  @extend .container;
  padding: 1rem 0;
  display: flex;
  align-items: center;
  font-family: 'Cormorant', serif;

  @include tablet {
    justify-content: space-between;
    padding: 1rem .6rem;
  }

  &__logo {
    width: 150px;

    &>a>img {
      width: 100%;
      height: auto;
    }

    @include tablet {
      width: 100px;
    }
  }

  &__nav {
    list-style: none;
    display: flex;
    padding-left: 2rem;

    @include tablet {
      padding-left: 0rem;
      padding-top: 1rem;
      display: none;
      position: fixed;
      align-items: center;
      top: 0;
      left: 0;
      right: 0;
      background-color: #fff;
      z-index: 999;
    }

    &>li {
      font-size: 18px;

      @include tablet {
        text-align: center;
      }

      &:not(:first-child) {
        margin-left: 3rem;

        @include tablet {
          margin-left: 0;
          margin-top: .6rem;
        }
      }
    }
  }

  &__hamberger {
    display: none;

    @include tablet {
      display: block;
      margin-right: .6rem;
    }
  }

  &__close {
    display: none;

    @include tablet {
      display: block;
    }
  }
}

.c-main {
  width: 100%;
  text-align: center;


}

.c-section {
  padding: 4rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: wrap;

  &__heading {
    width: 100%;
    height: 80px;
    font-family: 'Cormorant', serif;
    font-size: 50px;
    position: relative;

    @include tablet {
      font-size: 20px;
      height: 30px;
    }

    &>span {
      padding: 0 1rem;
      background-color: #fff;
      z-index: 2;
      position: relative;
    }

    &:after {
      content: '';
      display: block;
      width: 100%;
      height: 1px;
      background-color: #000;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      z-index: 1;
    }
  }

  &__body {
    font-size: 26px;
    font-weight: bold;
    margin-bottom: 2rem;

    @include tablet {
      font-size: 14px;
    }
  }

  &__message,
  &__characters,
  &__date,
  &__people {
    margin-bottom: 4.8rem;

  }

  &__message {
    width: 185px;
    height: auto;
    margin-left: auto;
    margin-right: auto;

    @include tablet {
      width: 180px;

    }
  }

  &__characters {
    width: 860px;
    height: auto;

    @include tablet {
      width: 96%;
    }
  }

  &__date {
    width: 270px;
    height: auto;

    @include tablet {
      width: 160px;
    }
  }

  &__people {
    width: 1080px;
    height: auto;
    margin-bottom: 0;

    @include tablet {
      width: 96%;
    }
  }
}

.c-intro {
  &__message {
    width: 680px;
    margin: 4rem 0;

    @include tablet {
      margin: 3rem 0;
      width: 300px;
      height: auto;
    }
  }

  &-content {
    width: 100%;
    background-image: url(../../rpp/images/introduction/intro-bg.png);
    background-position: center;
    background-repeat: no-repeat;
    background-color: rgba(255, 255, 255, 0.85);
    background-blend-mode: lighten;

    @include tablet {
      width: 96%;
      background-size: contain;
    }
  }
}

.c-footer {
  width: 100%;
  height: 320px;
  background-image: url(../../rpp/images/footer-bg.svg);
  background-position: bottom;
  background-repeat: repeat-x;
  padding-top: 6em;

  @include tablet {
    height: 196px;
    padding-top: 2em;
    background-image: url(../../rpp/images/footer-bg--sp.svg);
  }

  &__logo {
    width: 250px;
    margin: 0 auto 1rem;

    & img {
      width: 100%;
      height: auto
    }

    @include tablet {
      width: 110px;
      margin: 0 auto;

    }
  }

  &-content {
    @extend .container;
    display: flex;
    justify-content: space-between;

    @include tablet {
      flex-direction: column-reverse;
      justify-content: center;
      align-items: center;
    }

    &__copyright {
      font-family: 'Cormorant', serif;
      color: #fff;

      @include tablet {
        padding: 0 1rem;
        font-size: 12px;
        text-align: center;
      }
    }

    &__sns {
      @include tablet {
        margin-top: .6rem;
        margin-bottom: .6rem;
      }

      &>a {
        &>img {
          width: 44px;
          height: auto;

          @include tablet {
            width: 25px;
          }
        }


        &:not(:first-child) {
          margin-left: 1rem;
        }
      }
    }
  }
}

.container {
  width: 1100px;
  margin: 0 auto;

  @include tablet {
    width: 100%;
  }
}