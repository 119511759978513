@mixin mobile {
  @media screen and (max-width: $tablet - 1px) {
    @content;
  }
}

@mixin tablet {

  @media screen and (max-width: $tablet),
  print {
    @content;
  }
}

@mixin tablet-only {
  @media screen and (min-width: $tablet) and (max-width: $desktop - 1px) {
    @content;
  }
}

@mixin touch {
  @media screen and (max-width: $desktop - 1px) {
    @content;
  }
}

@mixin desktop {
  @media screen and (min-width: $desktop) {
    @content;
  }
}